<!-- Finder expansible de Tipos de Modalidades -->

<template>
  <div class="modalidadesMX" v-if="loaded">
    <v-sheet class="contenedor" :elevation="0">

      <!-- Botones Mto -->
      <div class="pb-2 d-flex justify-left">
        <baseBtraMto class="conflex" style="width:230px;"
          :modulo="btMtoCfg"
          :estado="estado"
          @onEvent="execAccion">
        </baseBtraMto>
      </div>


      <!-- Controles del Mto -->
      <div class="columna">
        <div class="conflex" style="align-items: center;">
          <uiText
            style="width:10%"
            v-model=" ct.id[2]"
            :label=" ct.id[1]"
            :disabled="noEdit || estado=='editar'">
          </uiText>

          <uiText
            style="width:70%"
            v-model=" ct.name[2]"
            :label=" ct.name[1]"
            :disabled="noEdit">
          </uiText>

          <v-checkbox
            style="width:20%;padding-left:15px;"
            v-bind="$checkbox"
            v-model="ct.pun[2]"
            :label="ct.pun[1]"
            :disabled="noEdit">
          </v-checkbox>
        </div>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseBtraMto from "@/base/baseBtraMto";

  export default {
    mixins: [mixM],
    components: { baseBtraMto },
    props: {},

    data() {
      return {
        stIni: {
          api: "modalidadesMX",
          name:"modalidadesMX",
          titulo:"",
          recordAccess:"local",
          mView:'modalidadesMX',
          pView:[],
          //relation:'tip_id'     // campo de relación entre el id de la tabla maestra y el tip_id de documentos
        },
      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV ********************** (iniDataParticular)");
      },


      // inicializo variables Btra Mto
      iniBtra() {
        this.btMtoCfg= JSON.parse(JSON.stringify(this.$cfe.btra.MX));
      },

      // compruebo si existe el ID antes de guardar
      /* guardarBefore() {
        if (this.$store.state[this.padre].read_records.filter(elem=> elem.id==this.schema.ctrls.id.value).length) {
          this.$root.$alert.open('El ID introducido ya existe', 'error');
          return false;
        }

        return true;
      }, */


      /* guardar_fin(apiResult) {
        if (apiResult.sql.error || !apiResult.r.length) return;
        this.$store.commit(this.padre + '/update_record', { opcion:'guardar', record:apiResult.r[0][0], id:apiResult.r[0][0].id });
      } */

    }
};
</script>
